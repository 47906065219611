html, body {
  height: 100%;
  margin: 0;
}

#root {
  height: 100%;
  margin: 0;
  display: flex;
}

#root > div {
  min-height: 100%;
  height: 1px; /* this is a total hack, but without it the footer will not work */
}

.content {
  min-height: 100%;
  flex: 1 0 auto;
}

.content-inside {
  padding: 20px;
  padding-bottom: 75px;
}

.footer {
  height: 75px;
  margin-top: -75px;
  background-color: #545454;
  padding: 15px;
  flex-shrink: 0;
}